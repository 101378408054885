import { useState } from "react";

import Button from "@containers/common/Button";
import Modal from "@containers/Orders/Order/components/common/Modal";

import { ConversationForm } from "./components";

const Conversation = () => {
  const [isConversationOpen, setIsConversationOpen] = useState<boolean>(false);
  const [stopModalCancellation, setStopModalCancellation] = useState(false);
  const [askQuestionModal, setAskQuestionModal] = useState(false);

  return (
    <div>
      <Button
        isOutlined
        onClick={() => setIsConversationOpen(true)}
      >
        Open Conversation
      </Button>
      <Modal
        hideTopSection={askQuestionModal}
        sx={{ padding: '24px' }}
        title="ORDER COMMENTS"
        open={isConversationOpen}
        handleClose={() => {
          if (!stopModalCancellation) {
            setAskQuestionModal(false);
            setIsConversationOpen(false);
          } else {
            setAskQuestionModal(true);
          }
        }}
      >
        <ConversationForm
          askQuestionModal={askQuestionModal}
          setAskQuestionModal={setAskQuestionModal}
          setIsConversationOpen={setIsConversationOpen}
          setStopModalCancellation={setStopModalCancellation}
        />
      </Modal>
    </div>
  );
};

export default Conversation;
