import React, { useEffect, useRef, useState } from "react";

import * as pdfjs from "pdfjs-dist";

import "pdfjs-dist/build/pdf.worker";

interface Props {
  height?: number;
  width?: number;
  url?: string;
}

const ImagePreview = ({ url, width = 70, height = 70 }: Props) => {
  const [isPdf, setIsPdf] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const checkIfPdf = () => {
      if (url) {
        const extension = url.split(".").pop()?.toLowerCase();

        setIsPdf(extension === "pdf");
      }
    };

    checkIfPdf();

    if (isPdf && url) {
      const renderPdf = async () => {
        try {
          const pdf = await pdfjs.getDocument(url).promise;
          const page = await pdf.getPage(1);

          const originalViewport = page.getViewport({ scale: 1 });
          const scale = Math.min(width / originalViewport.width, height / originalViewport.height);

          const viewport = page.getViewport({ scale });
          const canvas = canvasRef.current;

          if (canvas) {
            const context = canvas.getContext("2d");

            if (context) {
              canvas.width = viewport.width;
              canvas.height = viewport.height;

              const renderContext = {
                canvasContext: context,
                viewport,
              };

              await page.render(renderContext).promise;
            }
          }
        } catch (error) {
          console.error("Error rendering PDF:", error);
        }
      };

      renderPdf();
    }
  }, [url, width, height, isPdf]);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      {isPdf ? (
        <canvas
          ref={canvasRef}
          style={{
            display: "block",
            width: `${width}px`,
            height: `${height}px`,
            border: "none",
            cursor: "pointer",
          }}
        />
      ) : (
        <img
          src={url}
          alt="Preview"
          style={{
            width: `${width}px`,
            height: `${height}px`,
            objectFit: "cover",
            border: "none",
            cursor: "pointer",
          }}
        />
      )}
    </a>
  );
};

export default ImagePreview;
