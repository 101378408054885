import {
  createOptionalTextWidthValidation,
  createRequiredTextWidthValidation,
  numberValidation,
} from '@utils/schemas';
import * as yup from 'yup';

export const requiredIfItIsNotMailingService = yup.object().shape({
  phone: createRequiredTextWidthValidation('Phone', 255, undefined, 'Please enter a phone number.'),
  city: createOptionalTextWidthValidation('City', 255),
  company: createOptionalTextWidthValidation('Company', 255),
  country: createRequiredTextWidthValidation('Country', 255, undefined, 'Please select a country.'),
  address1: createRequiredTextWidthValidation('Address 1', 255, undefined, 'Please enter a valid street address.'),
  address2: createOptionalTextWidthValidation('Address 2', 255),
  fullName: createRequiredTextWidthValidation('Full Name', 255, undefined, 'Please enter your full name.'),
  state: createRequiredTextWidthValidation('State', 255, undefined, 'Please select a state or province.'),
  zipCode: createRequiredTextWidthValidation('Zip code', 10, 4),
});

const shipmentsSchema = yup.array().of(
  yup.object().shape({
    id: yup.string().uuid().required(),
    trackingNumber: createOptionalTextWidthValidation('Tracking Number', 255),
    trackingCarrier: yup.string().optional(),
    sets: yup.array().of(
      yup.object().shape({
        id: yup.string().uuid().required(),
        backProof: yup.string().nullable(),
        frontProof: yup.string().nullable(),
        backArtWork: yup.string().nullable(),
        frontArtWork: yup.string().nullable(),
        status: yup.string().required("Set status is required."),
      }),
    ).required(),
  }),
).required();

const jobsSchema = yup.object().shape({
  id: yup.string().required(),
  name: createRequiredTextWidthValidation('Name', 255),
  notes: createOptionalTextWidthValidation('Notes', 2000),
  mailingPO: createOptionalTextWidthValidation('Mailing PO', 255),
  mailingFile: yup.string().optional().nullable(),
  postageToVendor: yup.boolean().required(),
  customerPostage: yup.boolean().required(),
  postageFromVendor: yup.boolean().required(),
  shipments: shipmentsSchema,
  attributes: yup
  .array()
  .of(
    yup
    .object()
    .shape({
      attributeId: yup.string().required(),
      attributePrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
      attributeName: yup.string().required(),
    }),
  ).required(),
  editablePrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableShipping: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableTax: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  editableTotal: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  quantityPrice: numberValidation.max(999999999, 'The maximum number of digits is 9'),
  sendEmail: yup.boolean().required(),
  emails: yup
  .array()
  .of(
    createRequiredTextWidthValidation('emails', 255)
      .min(1, 'Must contain at least one email address'),
  )
  .optional(),
  textTemplate: yup.string().when('sendEmail', ([sendEmail]) => {
    return sendEmail
      ? createRequiredTextWidthValidation('Text template', 500)
      : createOptionalTextWidthValidation('Text template', 500);
  }),
  emailTemplateId: createOptionalTextWidthValidation('Email Template', 255),
  vendor: createOptionalTextWidthValidation('Vendor', 255),
  vendor2: createOptionalTextWidthValidation('Vendor 2', 255),
  vendor3: createOptionalTextWidthValidation('Vendor 3', 255),
  vendorJob: createOptionalTextWidthValidation('Vendor Job', 255),
  vendorJob2: createOptionalTextWidthValidation('Vendor Job2', 255),
  vendorJob3: createOptionalTextWidthValidation('Vendor Job3', 255),
  mailingQuantity: numberValidation.max(999999999, 'The maximum number of digits is 9').optional().nullable(),
  mailingFee: numberValidation.max(999999999, 'The maximum number of digits is 9').optional().nullable(),
  postageFee: numberValidation.max(999999999, 'The maximum number of digits is 9').optional().nullable(),
});

export const orderDetailsValidation = yup.object().shape({
  jobs: yup.array().of(jobsSchema).required(),
});
