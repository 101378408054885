import { Job } from "@features/orders/order/types";

import { IOrderForm } from "./formPayloadType";

export const preparingShipments = (job: Job) => {
  return job.shipments.map((shipment) => {
    const { sets } = shipment;

    const setsList = sets.map((
      {
      id,
      backProof,
      frontProof,
      backArtWork,
      frontArtWork,
      status,
    },
  ) => ({
      id,
      backProof,
      frontProof,
      backArtWork,
      frontArtWork,
      status,
  }));

    const newShipment = {
      id: shipment?.id,
      sets: setsList,
      trackingNumber: shipment.trackingNumber || '',
      trackingCarrier: shipment.trackingCarrier || '',
    };

    return newShipment;
  });
};

export const preparingFormData = (jobs: Job[]): IOrderForm[] => {
  const collection = jobs.map((job) => {
    return {
      id: job?.id,
      name: job?.name,
      notes: job?.notes || '',
      status: job?.status,
      mailingPO: job?.mailingPO || '',
      mailingFile: job?.mailingFile,
      postageToVendor: job.postageToVendor,
      customerPostage: job.customerPostage,
      postageFromVendor: job.postageFromVendor,
      shipments: preparingShipments(job),
      shipping: Number(job?.shipping),
      attributes: job?.jobAttributes,
      editablePrice: Number(job?.editablePrice),
      editableShipping: Number(job?.editableShipping),
      editableTax: Number(job?.editableTax),
      editableTotal: Number(job?.editableTotal),
      quantityPrice: Number(job?.quantityPrice),
      price: job?.price,
      tax: job.tax,
      total: job.total,
      sendEmail: false, // don't get from response
      emails: job?.emails || [],
      textTemplate: job?.textTemplate,
      emailTemplateId: job?.emailTemplateId,

      vendor: job?.vendor?.name || '',
      vendorId: job?.vendorId || null,
      vendorJob: job?.vendorJob || '',

      vendor2: job?.vendor2?.name || '',
      vendor2Id: job?.vendor2Id || null,
      vendorJob2: job?.vendorJob2 || '',

      vendor3: job?.vendor3?.name || '',
      vendor3Id: job?.vendor3Id || null,
      vendorJob3: job?.vendorJob3 || '',
      mailingQuantity: job?.mailingQuantity ? Number(job?.mailingQuantity) : null,
      mailingFee: job?.mailingFee ? Number(job?.mailingFee) : null,
      postageFee: job?.postageFee ? Number(job?.postageFee) : null,
    };
  });

  return collection;
};

export const preparingRequestPayload = ({ jobs }: { jobs: IOrderForm[]}) => {
      const jobsList = jobs;

      const newJobs = jobsList.map((job) => {
        if (job.vendorId === '') {
          job.vendorId = null;
        }

        return {
          ...job,
          attributes: job?.attributes.map((item) => ({
            ...item,
            attributePrice: Number(item.attributePrice),
          })),
          editablePrice: Number(job?.editablePrice),
          editableShipping: Number(job?.editableShipping),
          editableTax: Number(job?.editableTax),
          editableTotal: Number(job?.editableTotal),
          quantityPrice: Number(job?.quantityPrice),
          emails: job.emails?.length ? job.emails : null,
        };
      });

     return newJobs;
};

export const dynamicAccess = (obj: any, path: string) => {
  return path
      .replace(/\[(\d+)\]/g, '.$1') // Convert array indices from [0] to .0 for easier access
      .split('.') // Split the path by .
      .reduce((acc, key) => acc && acc[key], obj); // Traverse the object
};
