/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChangeEvent, DragEvent, ReactNode, memo } from "react";

import Box from "@mui/material/Box";
import Button from "@containers/common/Button";
import { InputTypes } from "@utils/globalTypes";

import { imgExtensions } from "../../helpers";

interface IFileUploaderInput {
  fileExtensions?: string[];
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  isMultiple?: boolean;
  loading?: boolean;
  btnProps?: Record<string, any>;
  type?: InputTypes;
}

const FileUploaderInput = ({
  fileExtensions,
  onDrop,
  onChange,
  children,
  isMultiple,
  loading,
  btnProps,
  type,
}: IFileUploaderInput) => {
  const acceptedExtensions = fileExtensions || imgExtensions;

  if (btnProps) {
    return (
      <Button disabled={loading} sx={{ position: "relative" }}>
        <Box
          component="label"
          sx={{
            position: "absolute",
            top: "auto",
            left: "0",
            width: "100%",
            height: "100%",
            lineHeight: "2.5",
            cursor: loading ? "unset" : "pointer",
          }}
        >
          <input
            type="file"
            accept={acceptedExtensions.join(", ")}
            style={{ display: "none" }}
            onChange={onChange}
            multiple={isMultiple}
            disabled={loading}
          />
          {btnProps.btnText}
        </Box>
      </Button>
    );
  }

  return (
    <Box
      onDrop={onDrop}
      onDragOver={(e) => e.preventDefault()}
      component="label"
    >
      <input
        type="file"
        accept={
          type && type === InputTypes.file
            ? "*/*"
            : acceptedExtensions.join(", ")
        }
        style={{ display: "none" }}
        onChange={onChange}
        multiple={isMultiple}
        disabled={loading}
      />
      {children}
    </Box>
  );
};

export default memo(FileUploaderInput);
