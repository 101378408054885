import { useEffect, useState } from "react";

import Button from "@containers/common/Button";
import { Typography } from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import circleArrowLeft from "@assets/images/circleArrowLeft.svg";
import circleArrowRight from "@assets/images/circleArrowRight.svg";
import { buildRoute } from "@routes/helpers";
import { selectOrderDetails } from "@features/orders/order/selectors";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectSetsIdList } from "@features/orders/sets/selectors";
import { ORDERS_IDS } from "@utils/constants";
import StorageManager from "@utils/storage-manager";
import { Ids } from "@features/orders/sets/types";
import { getCDNImagePath } from "@utils/helpers";
import { generateOrderPDF } from "@features/orders/order/actions";
import { getArrowIdsThunk } from "@features/orders/sets/actions";

import { Navbar, TopButtons, TopSection, BottomButtonsWrapper } from "./styled";

const TopNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [pdfLoading, setLoading] = useState(false);
  const { orderId = "" } = useParams();
  const ids = useAppSelector(selectSetsIdList);
  const { order } = useAppSelector(selectOrderDetails);
  const { orderNumber } = order;

  const [orderIds, setOrderIds] = useState<string[]>([]); // Store all order IDs
  const idsList = ids.length
    ? ids
    : (StorageManager.getItem(ORDERS_IDS) as Ids[]);

  const handleGeneratePDF = async () => {
    setLoading(true);

    try {
      const response = await dispatch(generateOrderPDF({ id: orderId })).unwrap();

      openInNewTab(getCDNImagePath(response));
      setLoading(false);
    } catch (error: any) {
      console.log(error);
    }
  };

  const openInNewTab = (url: string) => {
    window.open(url, "_blank", "noreferrer");
  };

  if (!orderId || orderId === "undefined") {
    return <Navigate to={PAGE_ROUTES.OPEN_ORDERS} />;
  }

  const handleSwitchOrder = async (direction: 'prev' | 'next') => {
    try {
      if (!orderIds.length && !order) {
        return;
      }

      const currentIndex = orderIds.indexOf(order?.id);
      const newIndex =
        direction === 'prev'
          ? currentIndex > 0
            ? currentIndex - 1
            : orderIds.length - 1
          : currentIndex < orderIds.length - 1
            ? currentIndex + 1
            : 0;

      if (newIndex === -1) {
        return;
      }

      const newOrderId = orderIds[newIndex];

      navigate(`/orders/${newOrderId}`);
    } catch (error) {
      console.error('Error switching order:', error);
    }
  };

  useEffect(() => {
    const fetchIds = async () => {
      try {
        const status = localStorage.getItem('arrowKind');

        dispatch(getArrowIdsThunk({ status: status || 'open' }))
          .unwrap()
          .then((response) => {
              setOrderIds(response?.data || []);
          });
      } catch (error) {
        console.error('Error fetching order IDs:', error);
      }
    };

    fetchIds();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Navbar>
      <TopSection>
        <Typography variant="h1" component="h1">
          Order ID:
          {' '}
          {orderNumber}
        </Typography>
        {idsList.length ? (
          <TopButtons>
            <img
              src={circleArrowLeft}
              alt="Circle Arrow Left"
              onClick={() => handleSwitchOrder("prev")}
            />
            <img
              src={circleArrowRight}
              alt="Circle Arrow Right"
              onClick={() => handleSwitchOrder("next")}
            />
          </TopButtons>
        ) : null}
        <BottomButtonsWrapper>
          <Button
            width={96}
            onClick={() => {
              const url = buildRoute(PAGE_ROUTES.OPEN_ORDERS_ADD_TASK, { orderId });

              navigate(url);
            }}
          >
            Add Task
          </Button>
          <Button
            width={96}
            onClick={() => {
              const url = buildRoute(PAGE_ROUTES.ORDER_ADD_INVOICE, { orderId });

              navigate(url);
            }}
          >
            Add Invoice
          </Button>

          <Button
            width={154}
            isOutlined
            disabled={pdfLoading}
            onClick={() => {
              handleGeneratePDF();
            }}
          >
            {pdfLoading ? "Loading" : 'View PDF Receipt'}
          </Button>
          {/* <Link to={archiveZip} download>
            <Button
              width={154}
              isOutlined
              disabled={pdfLoading}
            >
              Download All Artworks
            </Button>
          </Link> */}
        </BottomButtonsWrapper>
      </TopSection>
    </Navbar>
  );
};

export default TopNavbar;
