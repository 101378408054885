/* eslint-disable max-len */
import { SVGProps } from "react";

const PdfIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5020_20434)">
      <path d="M12 0V1.93939H22.0606V22.0606H12V24H24V0H12Z" fill="#891C00" />
      <path
        d="M1.93939 22.0606V1.93939H12V0H0V24H12V22.0606H1.93939Z"
        fill="#CD2A00"
      />
      <path
        d="M4.48852 19.0911L5.09792 19.4545C5.22311 19.5737 5.34229 19.5737 5.5866 19.5737C6.56561 19.5737 7.78743 18.3565 9.25511 15.7922C9.37579 15.6669 9.37579 15.6669 9.50103 15.6669C10.7167 15.1767 12.3097 14.9324 14.3867 14.6879C15.6101 15.3019 17.0717 15.5478 18.0491 15.5478C18.5394 15.5478 18.909 15.4225 19.1533 15.3019C19.3915 15.0575 19.5168 14.6879 19.636 14.5689C19.636 14.3245 19.636 14.0801 19.5168 13.8342C19.1533 13.2203 18.1743 12.982 16.7066 12.982C16.2179 12.982 15.7292 13.1012 15.1198 13.1012C14.6311 12.8568 14.3867 12.6125 14.0171 12.3681C13.2825 11.6335 12.5556 10.5354 12.0653 9.19291V9.06763C12.5556 7.48077 12.919 5.52435 12.0653 4.67063C11.8209 4.5454 11.5766 4.42627 11.3323 4.42627H11.0878C10.5977 4.42627 10.228 4.9165 10.1089 5.40528C9.6202 7.11122 9.86457 7.97109 10.3532 9.43878C9.9898 10.5353 9.6202 11.7587 9.0108 12.982C8.52207 13.8342 8.15252 14.5689 7.78747 15.1767C7.2988 15.4226 6.93531 15.667 6.69089 15.7922C5.22311 16.6444 4.48852 17.7424 4.36328 18.3565C4.36328 18.6024 4.36328 18.7214 4.48852 18.9659V19.0911ZM16.0988 13.8342H16.3371C18.0491 13.8342 18.5394 14.1993 18.6646 14.3245C18.6646 14.4436 18.7837 14.4436 18.7837 14.4436V14.5689H18.4187C17.8048 14.5689 16.951 14.3245 16.0988 13.9549L16.0988 13.8342ZM11.0878 5.03563C11.207 5.03563 11.207 4.91645 11.207 4.91645L11.3323 5.03563V5.16082C11.4513 5.27999 11.4513 5.76872 11.3323 6.38414V6.62855C11.3323 6.99199 11.207 7.2364 11.0878 7.72513C10.842 6.62855 10.842 5.76872 11.0878 5.03563ZM9.98975 13.4646C10.4724 12.368 10.9626 11.389 11.207 10.5353C11.6957 11.6334 12.4303 12.6124 13.0443 13.3454C13.1634 13.4646 13.2825 13.5898 13.5269 13.7089C12.5555 13.8342 11.0878 14.1992 9.50103 14.8131H9.25511C9.50103 14.3244 9.74539 13.9548 9.98975 13.4646ZM6.81002 16.5191C6.93526 16.4 7.17351 16.2748 7.41783 16.1556C6.69089 17.6233 5.95625 18.3565 5.22311 18.4771C5.34229 18.1121 5.9562 17.2537 6.81002 16.5191Z"
        fill="#CD2A00"
      />
    </g>
    <defs>
      <clipPath id="clip0_5020_20434">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PdfIcon;
