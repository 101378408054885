import React from 'react';

import MuiModal from '@mui/material/Modal';
import CloseModal from '@assets/images/close-modal.svg';
import { SxProps, Theme } from '@mui/material';

import { StyledBox, Wrapper, StyledTypography } from './styled';

interface ModalProps {
  title: string;
  open: boolean;
  handleClose: () => void;
  sx?: SxProps<Theme>;
  hideTopSection?: boolean;
  children: React.ReactNode;
}

const Modal = ({
  title,
  open,
  handleClose,
  sx,
  hideTopSection,
  children,
}: ModalProps) => {
  return (
    <MuiModal
      open={!!open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox
        sx={sx}
      >
        {
            !hideTopSection && (
            <Wrapper>
              <StyledTypography>{title}</StyledTypography>
              <img src={CloseModal} alt="" onClick={handleClose} />
            </Wrapper>
            )
          }
        {children}
      </StyledBox>
    </MuiModal>
  );
};

export default Modal;
