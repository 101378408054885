import { memo } from "react";

import { FormProvider } from "react-hook-form";
import StyledTable from "@containers/common/Table";
import SubmitBtn from "@containers/common/Table/components/SubmitBtn";
import TitlesWithBackButton from "@containers/common/TitlesWithBackButton";
import RowComponent from "@containers/common/Table/components/RowComponent";
import { StyledStack } from "@containers/common/StyledAddEditTables/styled";
import ReusableFields from "@containers/common/Table/components/ReusableFields";
import GlobalUpdatesMessage from "@containers/common/GlobalUpdatesMessage";

import { SendEmailWrapper } from "./styles";
import { useInvoiceCreateAndUpdateLogic } from "./useInvoiceCreateAndUpdateLogic";
import { invoiceStatuses } from "./data";

const InputsTable = () => {
  const {
    jobId,
    fields,
    orderId,
    filledData,
    isEdit,
    methods,
    isSuccessMsg,
    actionLoading,
    isDisabledSelect,
    emailInvoiceToCustomerField,
    onSubmit,
    handleSubmit,
    isSubmitting,
  } = useInvoiceCreateAndUpdateLogic();

  return (
    <TitlesWithBackButton
      path="ORDER"
      params={{ orderId, jobId }}
      title={isEdit ? "Edit Invoice" : "Add New Invoice"}
    >
      <FormProvider {...methods}>
        <StyledStack onSubmit={handleSubmit(onSubmit)} component="form">
          <StyledTable tableTitle="" colSpan={2}>
            {fields.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  filledData={filledData}
                  grandDisable={
                    item.field === "invoiceStatus" && isDisabledSelect
                  }
                  selectList={invoiceStatuses}
                />
              </RowComponent>
            ))}
          </StyledTable>
          <SendEmailWrapper>
            <ReusableFields {...emailInvoiceToCustomerField} />
            <p>Email this invoice to the customer after submitting the form</p>
          </SendEmailWrapper>
          <SubmitBtn
            disabled={isDisabledSelect || isSubmitting}
            text={isEdit ? "Save" : "Submit"}
            actionLoading={actionLoading}
          />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
