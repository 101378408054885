import Input from '@containers/common/Input';
import { Box } from '@mui/material';
import { IShipment } from '@features/orders/order/types';
import { ShipmentStatusesEnum } from '@features/orders/order/enums';
import { useFormContext } from 'react-hook-form';

import {
  FirstContentWrapper,
  FirstContentTypographyTitles,
  CarrierOverride,
  RadioAndLabelWrapper,
  ContentValue,
  TrackingNumberFirstContentWrapper,
  Underlined,
} from './styled';

interface ShipmentInfoProps {
  jobIndex: number;
  shipment: IShipment;
  shipmentIndex: number;
}

const ShipmentInfo = ({ jobIndex, shipment, shipmentIndex }: ShipmentInfoProps) => {
  const { setValue, watch, register, formState: { errors } } = useFormContext();
  const defaultKey = `jobs[${jobIndex}].shipments[${shipmentIndex}]`;

  const trackingCarrier = watch(`${defaultKey}.trackingCarrier`);
  const trackingNumber = watch(`${defaultKey}.trackingNumber`);

  const to = trackingCarrier === ShipmentStatusesEnum.UPS
          ? `https://www.fedex.com/wtrk/track/?tracknumbers=${trackingNumber}&cntry_code=us`
          : `https://www.ups.com/track?tracknum=${trackingNumber}`;

  const messagesObjJobs = errors?.jobs as Record<string, any>;

  let messageShipment = {} as Record<string, any>;

  if (messagesObjJobs && messagesObjJobs[jobIndex]) {
    messageShipment = messagesObjJobs[jobIndex]?.shipments?.[shipmentIndex]?.shipTo;
  }

  return (
    <Box>
      <TrackingNumberFirstContentWrapper>
        <FirstContentTypographyTitles variant="body3">
          Tracking Number:
        </FirstContentTypographyTitles>
        <Input
          {...register(`${defaultKey}.trackingNumber`)}
          errorMessage={messageShipment?.trackingNumber?.message}
        />
        {trackingCarrier && trackingNumber && (
          <Underlined
            to={to}
            target="_blank"
          >
            Track Order
            {' '}
            (
              {trackingCarrier}
            )
          </Underlined>
        )}
      </TrackingNumberFirstContentWrapper>
      <FirstContentWrapper>
        <FirstContentTypographyTitles variant="body3">
          Carrier Override:
        </FirstContentTypographyTitles>
        <ContentValue>
          <CarrierOverride>
            <RadioAndLabelWrapper>
              <input
                type="radio"
                name={`${shipment.id}-shipment`}
                value="UPS"
                checked={trackingCarrier === ShipmentStatusesEnum.UPS}
                onChange={() => {
                  setValue(`${defaultKey}.trackingCarrier`, ShipmentStatusesEnum.UPS);
                }}
              />
              <label>UPS</label>
            </RadioAndLabelWrapper>
            <RadioAndLabelWrapper>
              <input
                type="radio"
                name={`${shipment.id}-shipment`}
                value="FEDEX"
                checked={`${trackingCarrier || ''}`.toUpperCase() === ShipmentStatusesEnum.FEDEX}
                onChange={() => {
                  setValue(`${defaultKey}.trackingCarrier`, ShipmentStatusesEnum.FEDEX);
                }}
              />
              <label>FEDEX</label>
            </RadioAndLabelWrapper>
          </CarrierOverride>
        </ContentValue>
      </FirstContentWrapper>
      {
        shipment.facility && (
          <FirstContentWrapper>
            <FirstContentTypographyTitles variant="body3">
              Produced From:
            </FirstContentTypographyTitles>
            <ContentValue>
              {shipment.facility}
            </ContentValue>
          </FirstContentWrapper>
        )
      }
      {
        shipment?.dropShip && (
          <FirstContentWrapper>
            <FirstContentTypographyTitles variant="body3">
              Shipping Origin:
            </FirstContentTypographyTitles>
            <ContentValue color="red">
              {shipment.shippingOrigin}
            </ContentValue>
          </FirstContentWrapper>
        )
      }
      <FirstContentWrapper>
        <FirstContentTypographyTitles color={shipment.red ? 'red' : undefined}>
          Shipping Method:
        </FirstContentTypographyTitles>
        <ContentValue color={shipment.red ? 'red' : undefined}>
          {shipment.shippingMethod}
        </ContentValue>
      </FirstContentWrapper>
    </Box>
  );
};

export default ShipmentInfo;
