import * as yup from 'yup';
import { BillingInfo } from '@features/customers/customers/types';
import {
  EmailSchema,
  createRequiredTextWidthValidation,
  createTextWidthValidation,
  optionalEmailValidation,
  optionalPasswordValidation,
} from '@utils/schemas';

export interface IAddDataForm {
  id: string;
  email: string;
  created: string;
  IPAddress: string;
  status: string;
  affiliateRegistrationLink: string;
  whereYouHear: string;
  activatePaymentTerms: boolean;
  isReseller: boolean;
  resellerCertificate: string | null;
  isNonProfit: boolean;
  nonProfitCertificate: string | null;
  hideGeneralProducts: boolean;
  canSendFile: boolean;
  additionalEmail: string;
  commentsOrNotes: string;
  firstName: string;
  lastName: string;
  billingInfo: BillingInfo;
  password?: string;
  banned: boolean;
}

export const booleanSchema = (fieldName: string) => {
  return yup
    .boolean()
    .transform((value, originalValue) => {
      if (originalValue === '' || originalValue === null) {
        return null;
      }

      return Boolean(originalValue);
    })
    .nullable()
    .oneOf([true, false, null], `${fieldName} must be either true, false, or not set`)
    .required(`${fieldName} is required`);
};

const additionalEmail = yup
  .string()
  .nullable()
  .test(
    'validateEmails',
    'Please enter valid and unique emails separated by commas.',
    (value, context) => {
      if (!value) {
        return true;
      } // Allow null or empty value as valid

      // Split emails by comma, trim each, and filter out empty values
      const emails = value
        .split(',')
        .map((email) => email.trim())
        .filter((email) => email.length > 0);

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailSet = new Set<string>();

      for (const email of emails) {
        if (!emailRegex.test(email)) {
          // Push a specific error message for an invalid format
          return context.createError({
            message: `Invalid format for email: ${email}`,
          });
        }

        if (emailSet.has(email)) {
          // Push a specific error message for a duplicate email
          return context.createError({
            message: `Duplicate email detected: ${email}`,
          });
        }

        emailSet.add(email);
      }

      return true; // All emails are valid and unique
    },
  );

export const AddDataSchema = yup.object().shape({
  status: createRequiredTextWidthValidation('Customer status', 255),
  additionalEmail,
  email: EmailSchema,
  commentsOrNotes: createTextWidthValidation(255),
  password: optionalPasswordValidation(),
  isReseller: createRequiredTextWidthValidation('Is a Reseller', 255),
  isNonProfit: createRequiredTextWidthValidation('Is a Non-Profit', 255),
  activatePaymentTerms: yup.boolean().required(),
});

export const statusOptions = [
  { value: 'active',
    optionName: 'Active',
  },
  { value: 'disabled',
    optionName: 'Disabled',
  },
];

export const formattedPayload = (data: IAddDataForm) => {
  const payload = { ...data };

  if (!data.password) {
    delete payload.password;
  }

  return ({
    ...payload,
    additionalEmail: data.additionalEmail || null,
  });
};
