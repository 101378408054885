/* eslint-disable max-len */
import { SVGProps } from "react";

const AiIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5020_20361)">
      <rect width="30" height="30" fill="#FB7C33" />
      <rect x="1" y="1" width="23" height="23" fill="#261302" />
      <path
        d="M7.47651 13.2541L6.72419 16.2381C6.70864 16.3196 6.67752 16.3394 6.58306 16.3394H5.18844C5.0951 16.3394 5.07954 16.3068 5.0951 16.1915L7.79099 6.2955C7.83767 6.11505 7.86989 5.95904 7.88545 5.46657C7.88545 5.40137 7.91656 5.36761 7.96435 5.36761H9.9546C10.0168 5.36761 10.0491 5.38391 10.0646 5.46657L13.0894 16.2078C13.105 16.2893 13.0894 16.3394 13.0105 16.3394H11.4437C11.3659 16.3394 11.3181 16.3196 11.3025 16.2544L10.5191 13.2541H7.47651ZM10.1246 11.637L8.9967 6.96028L7.88434 11.637H10.1246ZM14.3974 6.1069C14.3974 5.46657 14.8208 5.08936 15.3686 5.08936C15.9487 5.08936 16.3398 5.50033 16.3398 6.1069C16.3398 6.76353 15.932 7.12444 15.3531 7.12444C14.7885 7.12444 14.3974 6.76353 14.3974 6.1069ZM14.5041 8.36901C14.5041 8.28634 14.5352 8.25375 14.6141 8.25375H16.1043C16.1832 8.25375 16.2143 8.28634 16.2143 8.36901V16.2241C16.2143 16.3068 16.1987 16.3394 16.1043 16.3394H14.6296C14.5352 16.3394 14.5041 16.2905 14.5041 16.2078V8.36901Z"
        fill="#FB7C33"
      />
    </g>
    <defs>
      <clipPath id="clip0_5020_20361">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AiIcon;
