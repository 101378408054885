import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import PAGE_ROUTES from '@routes/routingEnum';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { addTask, editTask } from '@features/customers/tasks/actions';
import { buildRoute } from '@routes/helpers';
import Textarea from '@containers/common/Textarea';
import useMount from '@customHooks/useMount';
import { getAllUsers } from '@features/users/actions';
import { resetUsers } from '@features/users/slice';
import { selectUsers } from '@features/users/selectors';
import Loader from '@containers/common/Loader';
import { selectTasks } from '@features/customers/tasks/selectors';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { GLOBAL_MESSAGES } from '@utils/messages';

import { AddDataSchema, EditDataSchema, defaultValues, statusOptions } from './helpers';
import { IAddDataForm, IInputsTable } from './types';
import TaskFor from './TaskFor';
import { inputsRows } from './tableData';

const InputsTable = ({ editData }: IInputsTable) => {
  const { customerId = '', taskId = '', orderId = '', jobId = '' } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectTasks);
  const { isLoading: usersLoading } = useAppSelector(selectUsers);
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const Schema = editData ? EditDataSchema : AddDataSchema;
  const methods = useForm<IAddDataForm>({
    resolver: yupResolver(Schema as any),
    defaultValues: editData ?? defaultValues,
  });

  const { handleSubmit, formState: { errors }, register } = methods;

  useMount(() => {
    dispatch(getAllUsers());

    return () => dispatch(resetUsers());
  });

  const handleNavigation = () => {
    let backPath = buildRoute(PAGE_ROUTES.EDIT_CUSTOMER, { taskId, id: customerId });

    if (orderId) {
      backPath = buildRoute(PAGE_ROUTES.ORDER, { orderId, jobId });
    }

    const backNavigation = customerId || orderId ? `${backPath}?tab=1` : PAGE_ROUTES.TASKS;

    navigate(backNavigation);
  };

  const onSubmit = (data: IAddDataForm) => {
    if (editData) {
      let editInfoPayload = { id: taskId, body: data };

      if (customerId) {
        editInfoPayload = { id: taskId, body: data };
      }

      dispatch(editTask(editInfoPayload))
        .unwrap()
        .then(() => {
          setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
        })
        .catch(() => {
          setIsSuccessMsg({ msg: 'Something went wrong.', status: 400 });
        });

      return;
    }

    if (orderId) {
      const localPayload = { ...data, orderId };

      dispatch(addTask(localPayload))
        .unwrap()
        .then(handleNavigation)
        .catch(handleNavigation);

      return;
    }

    dispatch(
      addTask(customerId ? { ...data, customerId } : data),
    )
      .unwrap()
      .then(handleNavigation)
      .catch(handleNavigation);
  };

  if (usersLoading) {
    return <Loader />;
  }

  let path: keyof typeof PAGE_ROUTES = orderId ? 'ORDER' : (customerId ? 'EDIT_CUSTOMER' : 'TASKS');
  let customParam: Record<string, any> = orderId ? { orderId } : (customerId ? { id: customerId } : {});

  if (orderId && jobId) {
    path = 'ORDER';
    customParam = { orderId, jobId };
  }

  return (
    <TitlesWithBackButton
      path={path}
      params={customParam}
      title={editData ? 'Edit Task' : 'Add Task'}
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="TASK" colSpan={2}>
            {inputsRows.map((item) => ((item.field === 'status' && !editData) ? null : (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[
                    {
                      field: 'status',
                      options: statusOptions,
                    },
                  ]}
                />
              </RowComponent>
            )))}
            <TaskFor />
            <RowComponent label="Notes">
              <Textarea
                placeholder="Notes"
                {...register('notes')}
                errorMessage={errors?.notes?.message as string}
                {...{
                  vtResize: true,
                  height: 'auto',
                }}
              />
            </RowComponent>
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <GlobalUpdatesMessage {...isSuccessMsg} />
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
