import { ChangeEvent, DragEvent, MouseEvent, memo, useState } from "react";

import Box from "@mui/material/Box";
import { FieldValues, UseFormWatch, useFormContext } from "react-hook-form";
import Stack from "@mui/material/Stack";
import FileUploaderInput from "@containers/common/FileUploader/components/FileUploaderInput/index";
import { useDispatch } from "react-redux";
import {
  updateJobMailingFile,
  updateJobSetInfo,
} from "@features/orders/order/actions";
import { InputTypes } from "@utils/globalTypes";
import { DragDropContext, Draggable, Droppable, DropResult } from "@hello-pangea/dnd";

import { getUploadUrl, uploadFile } from "./requests";
import { isValidFileExtension } from "./helpers";
import FileDisplay from "./components/FileDisplay";
import EmptyImage from "./components/EmptyImage";
import FileLoadingComponent from "./components/FileLoadingComponent";
import ErrorMessage from "../ErrorMessage";

interface IImageUpload {
  name: string;
  type?: InputTypes;
  errorMessage?: string;
  fileExtensions?: string[];
  isMultiple?: boolean;
  btnProps?: Record<string, any>;
  outOfFormSystem?: "sets" | "mailingFile";
}

interface HandleWithoutFormRequestProps {
  dispatch: any;
  fieldPath: string;
  updateLogicType?: "sets" | "mailingFile";
  watch: UseFormWatch<FieldValues>;
}

export const handleWithoutFormRequest = ({
   dispatch,
   watch,
   fieldPath,
   updateLogicType,
 }: HandleWithoutFormRequestProps) => {
  // eslint-disable-next-line no-useless-escape
  const route = fieldPath.split(/\.(?=[^\.]+$)/);

  if (updateLogicType === "sets") {
    const [setPath, updatedField] = route;
    const setCollection = watch(setPath);
    const { id } = setCollection;
    const value = setCollection[updatedField];

    dispatch &&
    dispatch(
      updateJobSetInfo({ id, payload: { [updatedField]: value || null } }),
    );
  } else if (updateLogicType === "mailingFile") {
    const [jobPath] = route;
    const jobCollection = watch(jobPath);
    const { id, mailingFile } = jobCollection;

    dispatch && dispatch(updateJobMailingFile({ id, mailingFile }));
  }
};

const FileUploader = ({
    name,
    btnProps,
    errorMessage,
    fileExtensions,
    outOfFormSystem,
    isMultiple = false,
    type,
  }: IImageUpload) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean | number>(false);
  const [fileDataList, setFileDataList] = useState<File[]>([]);
  const {
    setValue,
    watch,
    formState: { isSubmitted },
  } = useFormContext();

  const watchedValue = watch(name);
  const uploadedImg = Array.isArray(watchedValue)
    ? watchedValue
    : watchedValue
      ? [watchedValue]
      : [];

  const uploadToS3 = async (files: FileList, idx?: number) => {
    try {
      setLoading(idx !== undefined ? idx : true);

      if (files) {
        const { fileList } = await getUploadUrl(files);

        await uploadFile(files, fileList);

        const imgPaths = fileList.map(({ path }) => path);

        const newFileData = [...fileDataList, ...Array.from(files)];

        setFileDataList(newFileData);

        if (!isMultiple && imgPaths.length === 1) {
          setFileDataList([files[0]]);
          setValue(name, imgPaths[0], { shouldValidate: isSubmitted });
          if (outOfFormSystem) {
            handleWithoutFormRequest({
              dispatch,
              watch,
              fieldPath: name,
              updateLogicType: outOfFormSystem,
            });
          }
        } else {
          const newImages = [...uploadedImg, ...imgPaths];

          setValue(name, newImages, { shouldValidate: isSubmitted });
          if (outOfFormSystem) {
            handleWithoutFormRequest({
              dispatch,
              watch,
              fieldPath: name,
              updateLogicType: outOfFormSystem,
            });
          }
        }
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFiles = async (files: FileList, idx?: number) => {
    if (
      Array.from(files).every((file) =>
        isValidFileExtension(file, fileExtensions, type))
    ) {
      await uploadToS3(files, idx);
    }
  };

  const onChange = async (event: ChangeEvent<HTMLInputElement>, idx?: number) => {
    const { files } = event.target;

    files && (await handleFiles(files, idx));
  };

  const onDrop = async (event: DragEvent<HTMLDivElement>, idx?: number) => {
    event.preventDefault();

    const { files } = event.dataTransfer;

    files && (await handleFiles(files, idx));
  };

  const handleDeleteImg = (event: MouseEvent<SVGSVGElement>, idx?: number) => {
    event.preventDefault();
    event.stopPropagation();

    const newImages = uploadedImg.filter((_: any, i: number) => i !== idx);
    const newFileDataList = fileDataList.filter((_, i) => i !== idx);

    setValue(name, isMultiple ? newImages : null, { shouldValidate: isSubmitted });
    setFileDataList(newFileDataList);

    if (outOfFormSystem) {
      handleWithoutFormRequest({
        dispatch,
        watch,
        fieldPath: name,
        updateLogicType: outOfFormSystem,
      });
    }
  };

  const renderContent = () => {
    const handleDragEnd = (result: DropResult) => {
      if (isMultiple) {
        if (!result.destination) {
          return;
        }

        const reordered = [...uploadedImg];
        const reorderedFileData = [...fileDataList];

        const [movedItem] = reordered.splice(result.source.index, 1);
        const [movedItemDetail] = reorderedFileData.splice(result.source.index, 1);

        reordered.splice(result.destination.index, 0, movedItem);
        reorderedFileData.splice(result.destination.index, 0, movedItemDetail);

        setValue(name, reordered, { shouldValidate: isSubmitted });
        setFileDataList(reorderedFileData);
      }
    };

    return (
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={name} direction="vertical">
          {(provided) => (
            <Stack
              ref={provided.innerRef}
              {...provided.droppableProps}
              direction="column"
              flexWrap="wrap"
              alignItems="stretch"
              gap="8px"
            >
              {uploadedImg.map((item: any, idx: number) => (
                <Draggable key={item} draggableId={item} index={idx}>
                  {(providedDraggable) => (
                    <Box
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                    >
                      <FileDisplay
                        path={item}
                        fileData={fileDataList[idx]}
                        isMultiple={isMultiple}
                        handleDeleteImg={(e) => handleDeleteImg(e, idx)}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
              {(isMultiple || uploadedImg.length <= 0) && (
                <EmptyImage
                  type={type}
                  errorMessage={errorMessage}
                  fileExtensions={fileExtensions}
                  onDrop={onDrop}
                  onChange={onChange}
                  isMultiple={isMultiple}
                  loading={!!loading}
                />
              )}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  return btnProps ? (
    <FileUploaderInput onDrop={onDrop} onChange={onChange} {...btnProps} />
  ) : loading ? (
    <FileLoadingComponent />
  ) : (
    <Box>
      {renderContent()}
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </Box>
  );
};

export default memo(FileUploader);
