import { memo, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '@containers/common/Loader';
import PAGE_ROUTES from '@routes/routingEnum';
import { IDeveloperTask } from '@features/customers/developerTasks/types';
import { getDeveloperTaskById } from '@features/customers/developerTasks/actions';
import { selectDevelopersTasks } from '@features/customers/developerTasks/selectors';

import InputsTable from '../components/InputsTable';

const EditComponent = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { taskId } = useParams();
  const [developerTaskData, setDeveloperTaskData] = useState<IDeveloperTask | null>(null);
  const { isLoading } = useAppSelector(selectDevelopersTasks);

  useEffect(() => {
    if (taskId) {
      dispatch(getDeveloperTaskById({ id: taskId }))
        .unwrap()
        .then((data) => {
          const attachments = data?.attachment?.length ? data.attachment.flat() : null;

          setDeveloperTaskData({ ...data, attachment: attachments });
        })
        .catch(() => navigate(PAGE_ROUTES.DEVELOPERS_TASKS));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      {developerTaskData && <InputsTable developerTaskData={developerTaskData} />}
    </>
  );
};

export default memo(EditComponent);
