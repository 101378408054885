import { MouseEvent, memo } from "react";

import StyledTypography from "@containers/common/StyledTypography";
import EllipsisText from "@containers/common/EllipsisText";
import { getCDNImagePath } from "@utils/helpers";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import FileIcon from "@containers/common/Icons/FileIcon";
import PdfIcon from "@containers/common/Icons/PdfIcon";
import AiIcon from "@containers/common/Icons/AiIcon";
import PsdIcon from "@containers/common/Icons/PsdIcon";
import InddIcon from "@containers/common/Icons/InddIcon";
import { InputTypes } from "@utils/globalTypes";
import DragAndDropIcon from '@containers/common/Icons/DragAndDrop';
import Box from "@mui/material/Box";

import {
  StyledImgContainer,
  StyledTitleBox,
  StyledUploadContainer,
} from "../../styles";
import { imagesTypes } from "../../helpers";

interface IFileDisplay {
  fileData?: File;
  errorMessage?: string;
  handleDeleteImg: (e: MouseEvent<SVGSVGElement>) => void;
  path: string;
  width?: string;
  isMultiple?: boolean;
  isFile?: boolean;
  type?: InputTypes;
}

const FileDisplay = ({
  handleDeleteImg,
  isMultiple,
  fileData,
  errorMessage,
  path,
  width = "100%",
  isFile,
}: IFileDisplay) => {
  const getFileExtension = (filePath: string) => {
    return filePath.split(".")?.pop()?.toLowerCase();
  };

  const getFileIcon = (filePath: string) => {
    const extension = getFileExtension(filePath);

    switch (extension) {
      case "pdf":
        return (
          <span onClick={() => window.open(getCDNImagePath(path), "_blank")}>
            <PdfIcon />
          </span>
        );
      case "ai":
        return (
          <span onClick={() => window.open(getCDNImagePath(path), "_blank")}>
            <AiIcon />
          </span>
        );
      case "indd":
        return (
          <span onClick={() => window.open(getCDNImagePath(path), "_blank")}>
            <InddIcon />
          </span>
        );
      case "psd":
        return (
          <span onClick={() => window.open(getCDNImagePath(path), "_blank")}>
            <PsdIcon />
          </span>
        );
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "tiff":
      case "webp":
      case "ico":
      case "heif":
      case "svg":
      case "raw":
        return (
          <img
            src={getCDNImagePath(path)}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={() => window.open(getCDNImagePath(path), "_blank")}
          />
        );
      default:
        return <FileIcon />;
    }
  };

  return (
    <Stack gap="8px">
      <StyledUploadContainer
        error={!!errorMessage}
        width={width}
        isMultiple={isMultiple}
      >

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
          }}
        >
          <DragAndDropIcon />
        </Box>

        <StyledImgContainer
          isMultiple={isMultiple}
          isFile={isFile}
          alignItems="center"
          justifyContent="center"
        >
          {!isFile ? (
            <img
              src={getCDNImagePath(path)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => window.open(getCDNImagePath(path), "_blank")}
            />
          ) : fileData && imagesTypes.includes(fileData.type) ? (
            <img
              src={getCDNImagePath(path)}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={() => window.open(getCDNImagePath(path), "_blank")}
            />
          ) : (
            getFileIcon(path)
          )}
        </StyledImgContainer>
        <StyledTitleBox flexWrap="wrap">
          {fileData && (
            <EllipsisText variant="body3" maxWidth="159px" line={1}>
              {fileData?.name}
            </EllipsisText>
          )}
          {fileData && (
            <StyledTypography variant="body3" color="grey" minWidth="65px">
              {`${(fileData.size / 1024).toFixed(2)} KB`}
            </StyledTypography>
          )}
        </StyledTitleBox>

        <CloseIcon
          fontSize="small" // new: Adjust size for better UI fit
          color="inherit"
          cursor="pointer"
          onClick={handleDeleteImg}
          sx={{ marginLeft: "8px", width: '0.7em', height: '0.7em' }} // new: Add spacing for better layout

        />
      </StyledUploadContainer>
    </Stack>
  );
};

export default memo(FileDisplay);
