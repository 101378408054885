import { createAsyncThunk } from '@reduxjs/toolkit';
import { http } from '@services/RequestService';
import { customErrorHandling } from '@utils/errorHandler';
import { AxiosData, IReorderPayload } from '@utils/globalTypes';
import { Filters, constructQueryString } from '@utils/helpers';
import { AxiosResponse } from 'axios';

import {
  IAddCustomerPayload,
  ICustomer,
  ICustomerWithAdditionalEmail,
  ILoginAsCustomerResponse,
  ISearch,
} from './types';

const prefix = '/customers';

export const getAllCustomers = createAsyncThunk<ICustomer[], void, { rejectValue: AxiosData}>(
  'customers/all',
  async (_, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ICustomer[]>>(prefix);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const getCustomerById = createAsyncThunk<ICustomerWithAdditionalEmail, string, { rejectValue: AxiosData}>(
  'customers/getCustumer',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.get<AxiosResponse<ICustomerWithAdditionalEmail>>(`${prefix}/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const editCustomer = createAsyncThunk<void, IAddCustomerPayload, { rejectValue: AxiosData}>(
  'customers/edit',
  async (body, thunkAPI) => {
    try {
      await http.put<IAddCustomerPayload>(`${prefix}/${body.id}`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const deleteCustomer = createAsyncThunk<void, string, { rejectValue: AxiosData}>(
  'customers/delete',
  async (id, thunkAPI) => {
    try {
      await http.delete(`${prefix}/${id}`);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const reorderCustomers = createAsyncThunk<void, IReorderPayload, { rejectValue: AxiosData}>(
  'customers/reorder',
  async (body, thunkAPI) => {
    try {
      await http.patch<IReorderPayload>(`${prefix}/reorder`, body);
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const searchCustomers = createAsyncThunk<ISearch, Filters, { rejectValue: AxiosData}>(
  'customers/search',
  async (searchingData, thunkAPI) => {
    try {
      const queryParams = constructQueryString(searchingData);

      const { data: { data } } = await http.get<AxiosResponse<ISearch>>(
        `${prefix}/search?${queryParams}`,
      );

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);

export const exportCustomers = createAsyncThunk<Blob, void, { rejectValue: AxiosData}>(
  'customers/export',
  async (_, thunkAPI) => {
    try {
      const { data } = await http.get<Blob>(`${prefix}/export`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
export const loginAsCustomers = createAsyncThunk<ILoginAsCustomerResponse, string, { rejectValue: AxiosData}>(
  'customers/login',
  async (id, thunkAPI) => {
    try {
      const { data: { data } } = await http.post<AxiosResponse<ILoginAsCustomerResponse>>(`${prefix}/login/${id}`);

      return data;
    } catch (error) {
      const errorInfo = customErrorHandling(error);

      return thunkAPI.rejectWithValue(errorInfo);
    }
  },
);
