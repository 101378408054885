import { InputTypes } from "@utils/globalTypes";

export const imgExtensions = [".jpg", ".jpeg", ".png", ".svg"];
export const pdfExtensions = [".pdf"];
export const aiExtensions = [".ai"];
export const psdExtensions = [".psd"];
export const inddExtensions = [".indd"];
export const allFilesExtensions = [
  ...imgExtensions,
  ...pdfExtensions,
  ...psdExtensions,
  ...aiExtensions,
  ...inddExtensions,
  ".zip",
];

export const imagesTypes = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/svg+xml",
];

export const getMimeType = (filename: string): string => {
  const extension = filename.split(".").pop()?.toLowerCase();

  switch (extension) {
    case "jpg":
    case "jpeg":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "svg":
      return "image/svg+xml";
    case "webp":
      return "image/webp";
    case "pdf":
      return "application/pdf";
    default:
      return "application/octet-stream";
  }
};

export const isValidFileExtension = (
  file: File,
  fileExtensions?: string[],
  type?: InputTypes,
) => {
  const acceptedExtensions =
    fileExtensions ||
    (type && type !== InputTypes.file ? imgExtensions : allFilesExtensions);

  const extension = (file.name.match(/(\.\w+)$/) || [])[0]?.toLowerCase() || "";

  return acceptedExtensions.includes(extension);
};
