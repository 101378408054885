import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  name: string;
  description: string;
  folding: boolean;
}

export const defaultValues = {
  name: '',
  description: '',
  folding: false,
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
  description: createRequiredTextWidthValidation('Description', 500),
  folding: yup.boolean().required(),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Name',
    field: 'name',
    type: InputTypes.text,
  },
  {
    label: 'Description',
    field: 'description',
    type: InputTypes.textarea,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: 'Enable Attribute Images',
    field: 'folding',
    type: InputTypes.checkbox,
  },
];
