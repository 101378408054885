import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddDataForm {
  id?:string;
  name: string;
  logo: string;
  description: string;
  link: string;
}

export const defaultValues = {
  name: '',
  description: '',
  logo: '',
  link: '',
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
  description: createRequiredTextWidthValidation('Description', 5000),
  link: createRequiredTextWidthValidation('Logo', 255),
  logo: createRequiredTextWidthValidation('Link', 255),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Name',
    field: 'name',
    type: InputTypes.text,
  },
  {
    label: 'Logo',
    field: 'logo',
    type: InputTypes.image,
  },
  {
    label: 'Link',
    field: 'link',
    type: InputTypes.text,
  },
  {
    label: 'Description',
    field: 'description',
    type: InputTypes.textarea,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },

];
