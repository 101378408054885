import { memo } from 'react';

import Typography from '@mui/material/Typography';
import { ProofCustomerLogs } from '@features/orders/order/types';

import { Content, Colored } from '../../styled';

interface ProofCustomerProps {
  proofCustomerLogs: ProofCustomerLogs[];
}

const ProofCustomer = ({ proofCustomerLogs }: ProofCustomerProps) => {
  console.log(proofCustomerLogs);

  return (
    <Content>
      {
        [...proofCustomerLogs]
        .reverse()
        .map((log) => {
          let status = 'Accepted';
          let text = log?.text;

          // eslint-disable-next-line no-unsafe-optional-chaining
          const [dd, mm, rest] = text?.split('/');

          if (dd && mm && rest) {
            text = `${mm}/${dd}/${rest}`;
          }

          if (text?.includes('Accepted')) {
            status = 'Accepted';
          } else {
            status = 'Rejected';
          }

          const replacedText = text.replace(status, '');

          return (
            <Typography
              key={log.id}
              sx={{ fontSize: '12px' }}
            >
              {replacedText}
              <Colored status={status.toLocaleLowerCase()}>
                {status === 'Accepted' ? 'Approved' : status}
              </Colored>
            </Typography>
          );
        })
      }
    </Content>
  );
};

export default memo(ProofCustomer);
