import moment from 'moment';

import { AMAZON_S3_CDN_URL } from './constants';
import { ISelectOptions } from './globalTypes';

export const sleep = (delay = 0) =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

export const getCDNImagePath = (path: string) => `${AMAZON_S3_CDN_URL}/${path}`;

export const camelCaseToSensativeCase = (text: string): string => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

  return finalResult;
};

// Change the date format to Western style: MM/DD/YYYY
export const SHORT_DATE_FORMAT = 'MM/DD/YY';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const EXTRA_DATE_FORMAT = 'MM/DD/yyyy';

export const formattedDate = (date: string) => (
  moment(date).format(DATE_FORMAT)
);

// Add leading 0 if number has only one digit
export const addZeroToOneDigit = (num: number) => {
  return String(num).padStart(2, '0');
};

interface IItems {
  id: string;
  [key: string]: any;
}

export const getReorderedArray = (items: IItems[]) => {
  const orders = items.map(({ id }, index) => ({ id, sort: index + 1 }));

  return { orders };
};

export const getOptionsArray = (
  items?: IItems[] | null,
  fieldName?: string,
  anotherField?: string,
): ISelectOptions[] => {
  if (items) {
    return items.map((item) => ({
      value: item.id,
      optionName: fieldName ? `${item[fieldName]} ${anotherField ? item[anotherField] : ''}` : item.title,
    }));
  }

  return [];
};

export interface Filters {
  [key: string]: string | undefined | number;
}

export const constructQueryString = (filters: Filters): string => {
  return Object.entries(filters)
    .map(([key, value]) => value && `${key}=${encodeURIComponent(value)}`)
    .filter(Boolean)
    .join('&');
};

export const constructQueryStringExceptionWithOffer = (filters: Filters): string => {
  return Object.entries(filters)
    .map(([key, value]) => {
      if (value === 0 && key === 'offset') {
        return `${key}=${encodeURIComponent(value)}`;
      }

      return value && `${key}=${encodeURIComponent(value)}`;
    })
    .filter(Boolean)
    .join('&');
};

export const searchDefaultValue = (filters: Filters) => {
  for (const key in filters) {
    if (filters[key] === undefined) {
      filters[key] = '';
    }
  }

  return filters;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const priceFormatting = (price: string | number | undefined) => {
  return `$ ${parseFloat(`${price}`).toFixed(2)}`;
};
