import Attributes from './Attributes';
import BasicInfo from './BasicInfo/components/InputsTable';

export const tabsOptions = (onSuccess?: () => void) => [
  {
    component: (props: any) => <BasicInfo {...props} onSuccess={onSuccess} />,
    label: 'Basic Info',
  },
  {
    component: (props: any) => <Attributes {...props} />,
    label: 'Attributes',
  },
];
