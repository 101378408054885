import {
  createOptionalTextWidthValidation,
  createRequiredTextWidthValidation,
  optionalEmailValidationTwo,
} from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';
import { VendorCreationPayload } from '@features/vendors/types';

export interface IAddDataForm {
  id?:string;
  name: string;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  state: string | undefined;
  zipCode: string | undefined;
  phoneNumber: string | undefined;
  faxNumber: string | undefined;
  email: string | undefined;
  website: string | undefined;
  notes: string | undefined;
  excludeFromPOReport: boolean | undefined;
  carrierUsed: string;
}

export const defaultValues = {
  name: '',
  carrierUsed: '',
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation('Name', 255),
  address1: createOptionalTextWidthValidation('Address 1', 255),
  address2: createOptionalTextWidthValidation('Address 2', 255),
  city: createOptionalTextWidthValidation('City', 255),
  state: createOptionalTextWidthValidation('State', 255),
  zipCode: createOptionalTextWidthValidation('Zip code', 10, 4),
  phoneNumber: createOptionalTextWidthValidation('PhoneNumber', 255),
  faxNumber: createOptionalTextWidthValidation('Fax Number', 255),
  email: optionalEmailValidationTwo(),
  website: createOptionalTextWidthValidation('Website', 255),
  notes: createOptionalTextWidthValidation('Notes', 2000),
  excludeFromPOReport: yup.boolean().optional(),
  carrierUsed: createRequiredTextWidthValidation('Carrier Used', 255),
});

// Enter your notes...
export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Vendor name',
    field: 'name',
    placeholder: "Enter Name",
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Address 1',
    field: 'address1',
    placeholder: "Enter Address 1",
    type: InputTypes.text,
  },
  {
    label: 'Address 2',
    field: 'address2',
    placeholder: "Enter Address 2",
    type: InputTypes.text,
  },
  {
    label: 'City',
    field: 'city',
    placeholder: "Enter City",
    type: InputTypes.text,
  },
  {
    label: 'State',
    field: 'state',
    type: InputTypes.select,
  },
  {
    label: 'Zip code',
    field: 'zipCode',
    placeholder: "Enter Zip code",
    type: InputTypes.text,
  },
  {
    label: 'Phone number',
    field: 'phone',
    placeholder: "Enter phone number",
    type: InputTypes.text,
  },
  {
    label: 'Fax number',
    field: 'faxNumber',
    placeholder: "Enter fax number",
    type: InputTypes.text,
  },
  {
    label: 'Email',
    field: 'email',
    placeholder: "Enter email",
    type: InputTypes.text,
  },
  {
    label: 'Website',
    field: 'website',
    placeholder: "Enter website url",
    type: InputTypes.text,
  },
  {
    label: 'Notes',
    field: 'notes',
    placeholder: "Enter your notes...",
    type: InputTypes.textarea,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: 'Exclude from PO Report',
    field: 'excludeFromPOReport',
    type: InputTypes.checkbox,
  },
  {
    label: 'Carrier used',
    field: 'carrierUsed',
    type: InputTypes.select,
    isRequired: true,
  },
];

export const preparePayloadData = (data: IAddDataForm): VendorCreationPayload => {
  const convertedData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, value === "" ? null : value]),
  ) as VendorCreationPayload;

  return convertedData;
};

export const prepareEditFormData = (data: VendorCreationPayload) => {
  return Object.fromEntries(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(data).filter(([_, value]) => {
      if (typeof value === 'boolean' || value) {
        return true;
      }

      return false;
    }),
  ) as VendorCreationPayload;
};
