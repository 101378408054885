/* eslint-disable max-len */
import { SVGProps } from "react";

const InddIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5020_20363)">
      <rect width="30" height="30" fill="#F9488E" />
      <rect x="1" y="1" width="23" height="23" fill="#2C0213" />
      <path
        d="M7.6158 16.2041C7.6158 16.3047 7.60012 16.3394 7.48927 16.3394H6.0191C5.92393 16.3394 5.89258 16.2891 5.89258 16.2041V5.22457C5.89258 5.12286 5.93961 5.08936 6.0191 5.08936H7.50495C7.58445 5.08936 7.6158 5.12286 7.6158 5.20781V16.2041ZM9.28265 12.1586C9.28265 9.71164 10.8193 7.89049 13.2356 7.89049C13.4405 7.89049 13.5513 7.89049 13.7406 7.90724V5.19106C13.7406 5.12406 13.7876 5.08936 13.8357 5.08936H15.3932C15.4716 5.08936 15.4884 5.12286 15.4884 5.17431V14.7408C15.4884 15.0279 15.4884 15.3821 15.5354 15.7698C15.5354 15.8368 15.5153 15.8547 15.4526 15.8883C14.6318 16.3106 13.6859 16.3394 12.8651 16.3394C10.2349 16.3394 9.29069 14.4868 9.28265 12.1586ZM13.7406 9.55968C13.5984 9.49267 13.4091 9.45797 13.1717 9.45797C11.9401 9.45797 11.0768 10.4703 11.0768 12.1586C11.0768 14.0827 11.9558 14.858 13.0609 14.858C13.2983 14.858 13.5502 14.8245 13.7394 14.7396L13.7406 9.55968Z"
        fill="#F9488E"
      />
    </g>
    <defs>
      <clipPath id="clip0_5020_20363">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default InddIcon;
