import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

const reusableStyles = (isMultiple: boolean = false) => ({
  padding: "10px",
  borderRadius: "4px",
  cursor: "pointer",
  minHeight: isMultiple ? "48px" : "90px",
  width: isMultiple ? "100%" : "100%",
});

export const StyledUploadContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "error" && prop !== "width" && prop !== "isMultiple",
})<{ error?: boolean; width: string; isMultiple?: boolean }>(
  ({ theme, error, isMultiple }) => ({
    ...reusableStyles(isMultiple),
    display: "grid",
    gridTemplateColumns: `${isMultiple ? "32px 32px 1fr 32px" : "32px 82px 1fr 20px"}`,
    border: `1px solid ${error ? theme.palette.error.dark : theme.palette.grey[600]}`,
    gridColumnGap: "8px",
    alignItems: "center",
    padding: "8px",

  }),
);

export const StyledEmptyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "error" && prop !== "isMultiple",
})<{ error?: boolean; isMultiple?: boolean }>(
  ({ theme, error, isMultiple }) => ({
    ...reusableStyles(isMultiple),
    display: "flex",
    border: `1px dashed ${error ? theme.palette.error.dark : theme.palette.grey[500]}`,
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: error ? "6px" : "",
  }),
);

export const StyledImgContainer = styled(Stack, {
  shouldForwardProp: (prop) => prop !== "isMultiple" && prop !== "isFile",
})<{ isMultiple?: boolean; isFile?: boolean }>(
  ({ theme, isMultiple, isFile }) => ({
    width: isMultiple ? 32 : 74,
    minWidth: isMultiple ? 32 : 74,
    height: isMultiple ? 32 : 74,
    borderRadius: isFile ? "4px" : "",

    img: {
      width: "100%",

      height: "100%",
      objectFit: "cover",
    },
  }),
);
export const StyledTitleBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  columnGap: "8px",
}));
