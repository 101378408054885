import { memo } from 'react';

import TableCell from '@mui/material/TableCell';
import { StyledTableRow } from '@containers/common/Table/styled';
import { StyledTableCell } from '@containers/common/StyledAddEditTables/styled';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

import { StyledBox, InputsWrapper } from './styled';

const inputsRows1: ValidFieldNames[] = [
  {
    label: 'E.g. Multipage PDF, Front Side Artwork, etc.',
    field: 'frontArtworkLable',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'E.g. Multipage PDF, Front Side Artwork, etc.',
    field: 'backArtworkLable',
    type: InputTypes.text,
    isRequired: true,
  },
];

const ArtworkUploader = () => {
  return (
    <StyledTableRow>
      <StyledTableCell>Artwork uploader</StyledTableCell>
      <TableCell>
        <StyledBox>
          <InputsWrapper>
            {
              inputsRows1.map((item) => {
                return (
                  <ReusableFields
                    {...item}
                  />
                );
              })
            }
          </InputsWrapper>
        </StyledBox>
      </TableCell>
    </StyledTableRow>
  );
};

export default memo(ArtworkUploader);
