import { createRequiredTextWidthValidation } from '@utils/schemas';
import { InputTypes, ValidFieldNames } from '@utils/globalTypes';
import * as yup from 'yup';

export interface IAddBestSellerForm {
  id?: string;
  title: string;
  description: string;
  desktopPhoto: string;
  displayOnSite?: boolean;
  subCategory: string;
}

export const defaultValues = {
  id: '',
  title: '',
  description: '',
  desktopPhoto: '',
  subCategory: '',
  displayOnSite: false,
};

export const AddBestSellerSchema = yup.object().shape({
  title: createRequiredTextWidthValidation('Title', 255),
  description: createRequiredTextWidthValidation('Description', 500),
  desktopPhoto: createRequiredTextWidthValidation('Desktop photo', 255),
  subCategory: createRequiredTextWidthValidation('Subcategory', 255),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Photo',
    field: 'desktopPhoto',
    type: InputTypes.image,
    isRequired: true,
  },
  {
    label: 'Title',
    field: 'title',
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: 'Product Category',
    field: 'subCategory',
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: 'Description',
    field: 'description',
    type: InputTypes.textarea,
    isRequired: true,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
  {
    label: 'Visible on Site',
    field: 'displayOnSite',
    type: InputTypes.checkbox,
  },
];
