import { memo, useEffect, useRef, useState } from 'react';

import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectCategories } from '@features/categories/selectors';
import { selectProducts } from '@features/products/basicInfo/selectors';
import { getOptionsArray } from '@utils/helpers';
import { getSubcategoriesByCategoryId } from '@features/subcategories/actions';
import { ISubcategoriesByCategoryId } from '@features/subcategories/types';
import RowComponent from '@containers/common/Table/components/RowComponent';
import { IProductTypes } from '@features/productTypes/types';
import { getProductTypesBySubcategoryId } from '@features/productTypes/actions';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { inputsRows1 } from '../../tableData';
import { defaultGrandFormatValues } from '../../helpers';

const FirstRows = () => {
  const dispatch = useAppDispatch();
  const { allData: categories } = useAppSelector(selectCategories);
  const { allData: allProducts } = useAppSelector(selectProducts);
  const filteredList = categories?.filter((item) => item.subCategory?.length);
  const location = useLocation();

  const categoriesList = getOptionsArray(filteredList);

  const [subcategoriesList, setSubcategoriesList] = useState<ISubcategoriesByCategoryId[]>([]);
  const [productTypesList, setProductTypesList] = useState<IProductTypes[]>([]);

  const { setValue, watch } = useFormContext();

  const { categoryId, subCategoryId } = watch();
  const mountedRef = useRef(false);

  const isEditProductPage = location.pathname.includes('/products/edit');

  const fields = !isEditProductPage ? inputsRows1 : inputsRows1.filter((row) => row.field !== 'templateId');

  useEffect(() => {
    if (!categoryId) {
      setSubcategoriesList([]);
      setValue('subCategoryId', '');
      setValue('productTypeId', '');
      setProductTypesList([]);

      return;
    }

    dispatch(getSubcategoriesByCategoryId(categoryId))
      .unwrap()
      .then((data) => {
        setSubcategoriesList(data);

        if (!mountedRef.current) {
          if (subCategoryId) {
            dispatch(getProductTypesBySubcategoryId(subCategoryId)).unwrap().then((types) => {
              setProductTypesList(types);
            });
          }

          mountedRef.current = true;
        } else {
          setValue('subCategoryId', '');
          setValue('productTypeId', '');
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }

    if (subCategoryId) {
      dispatch(getProductTypesBySubcategoryId(subCategoryId)).unwrap().then((types) => {
        setProductTypesList(types);
        setValue('productTypeId', '');
      });
    } else {
      setProductTypesList([]);
      setValue('productTypeId', '');
    }

    const currentSubcategory = subcategoriesList.find((item) => item.id === subCategoryId);

    const isGrandProduct =
    currentSubcategory?.printType === "grand_format_products" &&
    currentSubcategory?.useGrandFormatSQFtTemplate;

    setValue('grandFormatOptions', isGrandProduct ? defaultGrandFormatValues : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subCategoryId]);

  let selectprops: Record<string, any> = {};

  return fields.map((item) => {
    if (item.field === 'productTypeId') {
      selectprops = { disabled: !productTypesList?.length };
    }

    return (
      <RowComponent key={item.label} {...item}>
        <ReusableFields
          {...item}
          grandDisable={item.field === 'productTypeId' && !productTypesList?.length}
          selectprops={selectprops}
          selectList={[...(isEditProductPage ? [] : [
            {
              field: 'templateId',
              options: getOptionsArray(allProducts, 'name'),
            },
          ]),
          {
            field: 'categoryId',
            options: categoriesList,
          },
          {
            field: 'subCategoryId',
            options: getOptionsArray(subcategoriesList),
          },
          {
            field: 'productTypeId',
            options: getOptionsArray(productTypesList, 'name'),
          }]}
        />
      </RowComponent>
    );
  });
};

export default memo(FirstRows);
