import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { selectOrderDetails } from "@features/orders/order/selectors";
import {
  addOrderInvoice,
  getInvoiceById,
  getOrderDetails,
  updateOrderInvoice,
} from "@features/orders/order/actions";
import { useConfirm } from "material-ui-confirm";
import createdSuccessOptionsDialog from "@containers/common/CreatedSuccess";
import confirmOptionsDialog from "@containers/common/Confirm";
import {
  InvoicePageMessageEnum,
  InvoiceStatusEnum,
} from "@features/invoices/types";
import { buildRoute } from "@routes/helpers";
import { priceFormatting } from "@utils/helpers";
import { GlobalUpdatesMessageProps } from "@containers/common/GlobalUpdatesMessage";
import { GLOBAL_MESSAGES } from "@utils/messages";

import { IInvoiceMutation } from "./type";
import { InvoiceCreateSchema, InvoiceUpdateSchema } from "./schema";
import {
  addInvoiceInputsRows,
  defaultValues,
  ediInvoiceInputsRows,
  emailInvoiceToCustomerField,
} from "./data";
import { prepareInvoiceData } from "./utils";

export const useInvoiceCreateAndUpdateLogic = () => {
  const message =
    "You’ll see the Total once the Item Price and the Tax fields are filled.";

  const actionLoading = false;
  const { orderId = "", jobId = "", invoiceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const confirm = useConfirm();
  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({
    msg: "",
    status: null,
  });

  const { order } = useAppSelector(selectOrderDetails);
  const [isDisabledSelect, setIsDisabledSelect] = useState(false);
  const [editData, setEditData] = useState<Record<string, any>>(
    {} as Record<string, any>,
  );

  const methods = useForm<any>({
    resolver: yupResolver(
      invoiceId ? InvoiceUpdateSchema : InvoiceCreateSchema,
    ),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods;

  const observeData = watch();

  const requestToOpenModal = async (isSendEmail: boolean) => {
    const maxWidth = isSendEmail ? "340px" : "308px";
    let text = isSendEmail
      ? InvoicePageMessageEnum.invoiceCreateWithEmail
      : InvoicePageMessageEnum.invoiceCreate;

    if (invoiceId) {
      text = isSendEmail
        ? InvoicePageMessageEnum.invoiceUpdateWithEmail
        : InvoicePageMessageEnum.invoiceUpdate;
    }

    await confirm(createdSuccessOptionsDialog({ text, maxWidth }));

    if (!invoiceId) {
      const path = buildRoute(PAGE_ROUTES.ORDER, { orderId, jobId });
      const pathTab2 = `${path}?tab=2`;

      navigate(pathTab2);
    }
  };

  const getInitialData = async () => {
    try {
      dispatch(getOrderDetails({ id: orderId }));

      if (invoiceId) {
        // getting info for update invoice
        dispatch(getInvoiceById({ id: invoiceId }))
          .unwrap()
          .then((invoice) => {
            setIsDisabledSelect(
              invoice.invoiceStatus === InvoiceStatusEnum.PAID,
            );

            const filledData = prepareInvoiceData({ invoice, order });

            reset(filledData);
            setEditData(filledData);
          });
      }

      if (order) {
        // getting info for create invoice
        const filledData = prepareInvoiceData({ order });

        reset(filledData);
        setEditData(filledData);
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const onSubmit = async (data: IInvoiceMutation) => {
    const payload = {
      tax: Number(data.tax),
      orderId: order.id,
      itemPrice: Number(data.itemPrice),
      itemDescription: data.itemDescription,
      emailInvoiceToCustomer: data.emailInvoiceToCustomer,
    };

    const handleInvoiceUpdate = async () => {
      if (!data?.invoiceStatus || !invoiceId) {
        return;
      }

      await dispatch(
        updateOrderInvoice({
          ...payload,
          invoiceId,
          invoiceStatus: data?.invoiceStatus as InvoiceStatusEnum,
        }),
      ).unwrap();

      await setIsDisabledSelect(data?.invoiceStatus === InvoiceStatusEnum.PAID);
      await requestToOpenModal(data.emailInvoiceToCustomer);
      setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });
    };

    const handleInvoiceCreation = async () => {
      await dispatch(addOrderInvoice(payload)).unwrap();
      await requestToOpenModal(data.emailInvoiceToCustomer);
    };

    try {
      if (data.invoiceStatus === InvoiceStatusEnum.CANCELLED) {
        await confirm(
          confirmOptionsDialog({
            questionText: "Are you sure you want to cancel this invoice?",
          }),
        )
          .then(async () => {
            await handleInvoiceUpdate();
          })
          .catch(console.log);

        return;
      }

      if (invoiceId) {
        await handleInvoiceUpdate();
      } else {
        await handleInvoiceCreation();
      }
    } catch (error: any) {
      if (typeof error?.message === "string") {
        setIsSuccessMsg({ msg: error?.message, status: 400 });
      }
    }
  };

  useEffect(() => {
    if (!orderId) {
      navigate(PAGE_ROUTES.ORDER);

      return;
    }

    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, invoiceId]);

  useEffect(() => {
    if (!Object.keys(order).length) {
      return;
    }

    if (invoiceId) {
      dispatch(getInvoiceById({ id: invoiceId }))
        .unwrap()
        .then((invoice) => {
          const filledData = prepareInvoiceData({ invoice, order });

          reset(filledData);
          setEditData(filledData);
        });

      return;
    }

    const filledData = prepareInvoiceData({ order });

    reset(filledData);
    setEditData(filledData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order]);

  useEffect(() => {
    const tax = Number(observeData.tax);
    const itemPrice = Number(observeData.itemPrice);

    if (invoiceId) {
      if (tax && itemPrice) {
        // reset({ ...observeData, subtotal: `${priceFormatting(tax + itemPrice)}` });
        setEditData({
          ...observeData,
          subtotal: `${priceFormatting(tax + itemPrice)}`,
        });
      } else if (observeData.total !== message) {
        // reset({ ...observeData, subtotal: `${priceFormatting(message)}` });
        setEditData({ ...observeData, subtotal: observeData.subtotal });
      }

      return;
    }

    if (tax && itemPrice) {
      reset({ ...observeData, total: `${priceFormatting(tax + itemPrice)}` });
      setEditData({
        ...observeData,
        total: `${priceFormatting(tax + itemPrice)}`,
      });
    } else if (observeData.total !== message) {
      reset({ ...observeData, total: `${priceFormatting(message)}` });
      setEditData({ ...observeData, total: message });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps, react-hooks/exhaustive-deps
  }, [observeData.tax, observeData.itemPrice]);

  let fields = [...addInvoiceInputsRows];

  if (invoiceId) {
    fields = ediInvoiceInputsRows;
  }

  return {
    jobId,
    fields,
    orderId,
    methods,
    invoiceId,
    isSuccessMsg,
    actionLoading,
    isDisabledSelect,
    isEdit: invoiceId,
    filledData: editData,
    emailInvoiceToCustomerField,
    watch,
    onSubmit,
    handleSubmit,
    isSubmitting,
  };
};
