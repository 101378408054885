export const headCells = [
  {
    label: 'Product Category',
  },
  {
    label: 'PRINT CATEGORY',
  },
  {
    label: 'SPOTLIGHT',
  },
  {
    label: 'VISIBLE ON SITE',
  },
  {
    label: 'ACTIONS',
    colSpan: 2,
  },
];
