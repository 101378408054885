import { memo, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledTable from '@containers/common/Table';
import { StyledStack } from '@containers/common/StyledAddEditTables/styled';
import TitlesWithBackButton from '@containers/common/TitlesWithBackButton';
import ReusableFields from '@containers/common/Table/components/ReusableFields';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import SubmitBtn from '@containers/common/Table/components/SubmitBtn';
import RowComponent from '@containers/common/Table/components/RowComponent';
import PAGE_ROUTES from '@routes/routingEnum';
import { GLOBAL_MESSAGES } from '@utils/messages';
import { IDeveloperTask } from '@features/customers/developerTasks/types';
import { addDeveloperTask, editDeveloperTask } from '@features/customers/developerTasks/actions';
import GlobalUpdatesMessage, { GlobalUpdatesMessageProps } from '@containers/common/GlobalUpdatesMessage';
import { Box } from '@mui/material';

import { AddDataSchema, IAddDataForm, inputsRows, defaultValues, statusOptions, priorityOptions } from './helpers';

interface IInputsTable {
  developerTaskData?: IDeveloperTask;
}

const InputsTable = ({ developerTaskData }: IInputsTable) => {
  const dispatch = useAppDispatch();
  const { taskId } = useParams();
  const navigate = useNavigate();
  const { actionLoading } = useAppSelector(selectAttributeCategories);
  const methods = useForm<IAddDataForm>({
    // @ts-ignore
    resolver: yupResolver(AddDataSchema),
    defaultValues: developerTaskData ?? defaultValues,
  });

  const [isSuccessMsg, setIsSuccessMsg] = useState<GlobalUpdatesMessageProps>({ msg: '', status: null });

  const { handleSubmit } = methods;

  const onSubmit = (data: IAddDataForm) => {
    dispatch(
      developerTaskData && taskId
        ? editDeveloperTask({ ...data, attachment: data?.attachment ?? null })
        : addDeveloperTask({ ...data, attachment: data?.attachment ?? null }),
    )
      .unwrap()
      .then(() => {
        setIsSuccessMsg({ msg: GLOBAL_MESSAGES.update, status: 200 });

        if (!developerTaskData) {
          navigate(PAGE_ROUTES.DEVELOPERS_TASKS);
        }
      }).catch((error) => {
        if (error.message === "Internal server error") {
          setIsSuccessMsg({ msg: 'Something went wrong', status: 400 });
        } else {
          setIsSuccessMsg({ msg: 'Something went wrong', status: 400 });
        }
      });
  };

  return (
    <TitlesWithBackButton
      title={developerTaskData ? 'Edit Developer task' : 'Add Developer task'}
      path="DEVELOPERS_TASKS"
    >
      <FormProvider {...methods}>
        <StyledStack
          onSubmit={handleSubmit(onSubmit)}
          component="form"
        >
          <StyledTable tableTitle="Developers Tasks" colSpan={2}>
            {inputsRows.map((item) => (
              <RowComponent key={item.label} {...item}>
                <ReusableFields
                  {...item}
                  selectList={[
                    {
                      field: 'status',
                      options: statusOptions,
                    },
                    {
                      field: 'priority',
                      options: priorityOptions,
                    },
                  ]}
                />
              </RowComponent>
            ))}
          </StyledTable>
          <SubmitBtn actionLoading={actionLoading} />
          <Box sx={{ textAlign: 'center' }}>
            <GlobalUpdatesMessage {...isSuccessMsg} />
          </Box>
        </StyledStack>
      </FormProvider>
    </TitlesWithBackButton>
  );
};

export default memo(InputsTable);
