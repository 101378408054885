import { memo, useCallback, useState } from "react";

import useMount from "@customHooks/useMount";
import Loader from "@containers/common/Loader";
import { useAppDispatch, useAppSelector } from "@features/app/hooks";
import { getSubcategoryById } from "@features/subcategories/actions";
import { useNavigate, useParams } from "react-router-dom";
import PAGE_ROUTES from "@routes/routingEnum";
import { selectSubcategories } from "@features/subcategories/selectors";
import { ISubcategoriesInfo } from "@features/subcategories/types";
import { getAllCategories } from "@features/categories/actions";
import { selectCategories } from "@features/categories/selectors";
import EmptyState from "@containers/common/EmptyState";
import { getAllShippingZips } from "@features/shippingZip/actions";
import { selectShippingZips } from "@features/shippingZip/selectors";

import InputsTable from "../..";

const EditProductCategory = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading: categoriesLoading, allData: categories } = useAppSelector(selectCategories);
  const { isLoading: shippingZipsLoading } = useAppSelector(selectShippingZips);

  const { id } = useParams();
  const [editData, setEditData] = useState<ISubcategoriesInfo | null>(null);
  const { isLoading } = useAppSelector(selectSubcategories);

  const fetchData = useCallback(() => {
    if (id) {
      dispatch(getSubcategoryById(id))
        .unwrap()
        .then((data) => setEditData(data))
        .catch(() => navigate(PAGE_ROUTES.PRODUCT_CATEGORIES));
    }

    dispatch(getAllCategories());
    dispatch(getAllShippingZips());
  }, [dispatch, id, navigate]);

  const handleSuccess = useCallback(() => {
    fetchData();
  }, [fetchData]);

  useMount(fetchData);

  if (isLoading || categoriesLoading || shippingZipsLoading) {
    return <Loader />;
  }

  return (
    <>
      {categories?.length && editData ? (
        <InputsTable
          onSuccess={handleSuccess}
          editData={
            {
              ...editData,
              // @ts-ignore
              photo: [editData.photo, ...editData.additionalPhotos],
            } as any
          }
        />
      ) : (
        <EmptyState text="You don’t have any categories" isAdd />
      )}
    </>
  );
};

export default memo(EditProductCategory);
