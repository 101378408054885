import { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { format } from 'date-fns';
import Button from '@containers/common/Button';
import { Box, Typography } from '@mui/material';
import Checkbox from '@containers/common/Checkbox';
import { useAppDispatch, useAppSelector } from '@features/app/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IConversationForm } from '@features/conversation/types';
import { getConversationLastMessage, getConversations, sendMessage } from '@features/conversation/actions';
import { useParams } from 'react-router-dom';
import {
  getConversationSelector,
  getConversationInitLoaderSelector,
  getConversationMessageSelector,
} from '@features/conversation/selectors';
import { EXTRA_DATE_FORMAT } from '@utils/helpers';
import { addNewMessage, clearMessages } from '@features/conversation/slice';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorMessage from '@containers/common/ErrorMessage';
import moment from 'moment';

import Dialog from './Dialog';
import { ConversationSchema } from './helpers';
import {
  Section,
  LoaderWrapper,
  MessagesList,
  LeftMessage,
  RightMessage,
  StyledTextarea,
  SendEmailWrapper,
  MissingMessage,
 } from './styled';

interface ConversationFormProps {
  askQuestionModal: boolean;
  setAskQuestionModal: Dispatch<SetStateAction<boolean>>;
  setIsConversationOpen: Dispatch<SetStateAction<boolean>>;
  setStopModalCancellation: Dispatch<SetStateAction<boolean>>;
}

const ConversationForm = ({
  askQuestionModal,
  setAskQuestionModal,
  setIsConversationOpen,
  setStopModalCancellation,
 }: ConversationFormProps) => {
  const { orderId } = useParams();
  const dispatch = useAppDispatch();
  const conversation = useAppSelector(getConversationSelector);
  const initLoader = useAppSelector(getConversationInitLoaderSelector);
  const message = useAppSelector(getConversationMessageSelector);

  const methods = useForm<IConversationForm>({
    resolver: yupResolver(ConversationSchema),
    defaultValues: {
      message: '',
      sendEmail: false,
    },
  });

  const { reset, watch, handleSubmit, register, formState: { errors } } = methods;
  const prevLoadedHeightRef = useRef<number>(0);
  const bottomRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const messageFiled = watch('message');

  const [once, setOnce] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [filters, setFilters] = useState({ limit: 10, offset: 0 });

  const onSubmit = async (data: IConversationForm) => {
    if (!orderId) {
      return;
    }

    try {
      const response = await dispatch(sendMessage({ ...data, orderId })).unwrap();

      await dispatch(addNewMessage(response));

      if (bottomRef?.current) {
        bottomRef.current.scrollIntoView();
      }

      reset();
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleScroll = (e: any) => {
    if (!prevLoadedHeightRef.current) {
      prevLoadedHeightRef.current = e.target.scrollHeight;
    }

    if (e.target.scrollTop === 0 && !moreLoading) {
      setMoreLoading(true);

      setTimeout(() => {
        // Just for loader, without setTimeout it works well
        setFilters((prev) => ({ ...prev, offset: prev.offset + prev.limit }));
      }, 100);
    }
  };

  useEffect(() => {
    setStopModalCancellation(!!messageFiled);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageFiled]);

  useEffect(() => {
    if (!orderId) {
      return;
    }

    dispatch(getConversations({ id: orderId, ...filters }))
      .unwrap()
      .then(() => {
        setMoreLoading(false);
      })
      .catch(() => {
        setMoreLoading(false);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(clearMessages());

       if (orderId) {
        dispatch(getConversationLastMessage({ id: orderId }));
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    // it works one time, when page loads
    if (bottomRef.current && !once) {
      setOnce(true);
      bottomRef.current.scrollIntoView();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation]);

  useLayoutEffect(() => {
    if (containerRef.current && once) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight - prevLoadedHeightRef.current;

       setTimeout(() => {
        prevLoadedHeightRef.current = 0;
      }, 0);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation.length]);

  useLayoutEffect(() => {
    const element = containerRef.current;

    if (element && !!conversation.length) {
      element.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener('scroll', handleScroll);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!conversation.length]);

  const viewRender = () => {
    if (initLoader) {
      return (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      );
    }

    if (
      !conversation?.length && initLoader ||
      !conversation?.length && !initLoader && message
    ) {
      return (
        <MissingMessage>
          There are no comments for this order yet.
        </MissingMessage>
      );
    }

    return (
      <div>
        <MessagesList ref={containerRef}>
          {
            moreLoading && (
              <Box
                sx={{
                  maxHeight: '60px',
                  height: '60px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            )
          }
          {
            !moreLoading && message && (
              <MissingMessage
                sx={{ marginBottom: '16px' }}
              >
                {message}
              </MissingMessage>
            )
          }
          {
            conversation?.map((item) => {
              if (item.sender === 'admin') {
                return (
                  <RightMessage key={item.id}>
                    <Typography>
                      {item.message}
                      <span>{ moment(item.created).format(`${EXTRA_DATE_FORMAT} HH:mm a`)}</span>
                    </Typography>
                  </RightMessage>
                );
              }

              return (
                <LeftMessage key={item.id}>
                  <Typography>
                    {item.message}
                    <span>{ moment(item.created).format(`${EXTRA_DATE_FORMAT} HH:mm a`)}</span>
                  </Typography>
                </LeftMessage>
              );
            })
          }
          <div ref={bottomRef} />
        </MessagesList>
      </div>
    );
  };

  if (askQuestionModal) {
    return (
      <Dialog
        setAskQuestionModal={setAskQuestionModal}
        setIsConversationOpen={setIsConversationOpen}
        setStopModalCancellation={setStopModalCancellation}
      />
    );
  }

  return (
    <Section>
      <FormProvider {...methods}>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          {viewRender()}
          <StyledTextarea
            onlyBorder
            placeholder="Reply here..."
            value={messageFiled}
            {...register('message')}
            errorMessage={errors?.message?.message}
          />
          <SendEmailWrapper>
            <Checkbox name="sendEmail" />
            <Typography sx={{ marginTop: '2px' }}>
              Send an email update to customer after submitting your reply
            </Typography>
          </SendEmailWrapper>
          {errors?.message?.message && (
            <Box sx={{ textAlign: 'center', marginBottom: '12px' }}>
              <ErrorMessage message={errors?.message?.message as string} />
            </Box>
          )}
          <Button type="submit">Send</Button>
        </form>
      </FormProvider>
    </Section>
  );
};

export default ConversationForm;
