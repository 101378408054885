import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const StyledBox = styled(Box)(() => ({
  width: '100%',
  columnGap: '5px',
  alignItems: 'flex-end',
  padding: '20px 10px',
  gridTemplateColumns: '40px 1fr',
}));

export const InputsWrapper = styled(Box)(() => ({
  '>div:nth-of-type(1)': {
    marginBottom: '16px',
  },
}));
