export interface IState {
  isLoading: boolean;
  topBanners: IBanner[];
  bottomBanners: IBanner[];
  actionLoading: boolean;
  errorMessage: null | string;
}

export enum BannerCategoryEnum {
  banner= 'top_banner',
  slider= 'bottom_banner',
}

export interface IAddBannerPayload {
  id?: string;
  title: string;
  description: string;
  desktopPhoto: string;
  mobilePhoto: string;
  backgroundColor: string;
  firstButtonName?: string;
  firstButtonLink?: string;
  secondButtonName?: string;
  secondButtonLink?: string;
  category?: BannerCategoryEnum | string;
  displayOnSite?: boolean;
}

export interface IBanner {
  id: string;
  created: string;
  updated?: string;
  deleted: any;
  title: string;
  description: string;
  sort: number;
  displayOnSite: boolean;
  category: string;
  desktopPhoto: string;
  mobilePhoto: string;
  backgroundColor: string;
  textColor?: string;
  firstButtonName?: string;
  firstButtonLink?: string;
  secondButtonName?: string;
  secondButtonLink?: string;
  iconPhoto?: string;
  iconText?: string;
  iconTextColor?: string;
  visibleOnlyRegisteredUsers: boolean;
}

export interface IBanners {
  topBanners: IBanner[];
  bottomBanners: IBanner[];
}
