import { ChangeEvent, DragEvent, memo } from "react";

import StyledTypography from "@containers/common/StyledTypography";
import Typography from "@mui/material/Typography";
import UploadIcon from "@containers/common/Icons/UploadIcon";
import { InputTypes } from "@utils/globalTypes";

import { StyledEmptyContainer } from "../../styles";
import FileUploaderInput from "../FileUploaderInput";

interface IEmptyImage {
  errorMessage?: string;
  onDrop: (event: DragEvent<HTMLDivElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isMultiple?: boolean;
  width?: string;
  fileExtensions?: string[];
  loading?: boolean;
  type?: InputTypes;
}

const EmptyImage = ({
  errorMessage,
  onDrop,
  onChange,
  isMultiple,
  width = "100%",
  fileExtensions,
  loading,
  type,
}: IEmptyImage) => (
  <FileUploaderInput
    type={type}
    loading={loading}
    onDrop={onDrop}
    onChange={onChange}
    isMultiple={isMultiple}
    fileExtensions={fileExtensions}
  >
    <StyledEmptyContainer
      error={!!errorMessage}
      width={width}
      isMultiple={isMultiple}
    >
      <Typography variant="body3">Drag and drop files or </Typography>
      <StyledTypography variant="body3" color="blue" m="0 16px 0 4px" underLine>
        Browse
      </StyledTypography>
      <UploadIcon />
    </StyledEmptyContainer>
  </FileUploaderInput>
);

export default memo(EmptyImage);
