import { memo } from 'react';

import { useAppSelector } from '@features/app/hooks';
import { selectAttributeCategories } from '@features/attributeCategories/selectors';
import EmptyState from '@containers/common/EmptyState';
import Loader from '@containers/common/Loader';
import { selectSubcategories } from '@features/subcategories/selectors';

import InputsTable from './components/InputsTable';

const Attributes = () => {
  const { attributesLoading } = useAppSelector(selectSubcategories);
  const { productAttributeCategories, isLoading } = useAppSelector(selectAttributeCategories);

  if (isLoading || attributesLoading) {
    return <Loader />;
  }

  return (
    <>
      {productAttributeCategories?.length ? <InputsTable /> : (
        <EmptyState text="You don’t have any attribute categories" isAdd />
      )}
    </>
  );
};

export default memo(Attributes);
