import { FormProvider } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import GlobalUpdatesMessage from '@containers/common/GlobalUpdatesMessage';
import { Box } from '@mui/material';

import Modal from '../../../../common/Modal';
import MainJobInformation from './components/MainJobInformation';
import SubTotal from './components/SubTotal';
import { ShipToDropShipModal } from './components/MainJobInformation/Shipment/components';
import { useOrderDetailsLogic } from './useOrderDetailsLogic';
import { OrderDetailsContainer, StyledButton } from './styled';
import { DropShipShipToContext } from './utils/context';

const OrderDetails = () => {
  const {
    open,
    jobs,
    order,
    value,
    methods,
    type,
    vendorsList,
    shippingLink,
    submitLoading,
    isSuccessMsg,
    onSubmit,
    handleClose,
  } = useOrderDetailsLogic();

  // eslint-disable-next-line no-console
  console.log('Order Details Errors: ', methods?.formState?.errors);

  return (
    <DropShipShipToContext.Provider value={value}>
      <FormProvider {...methods}>
        <Stack
          component="form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <OrderDetailsContainer>
            {jobs.map((job, index) => (
              <MainJobInformation
                job={job}
                key={job.id}
                jobIndex={index}
                vendorsList={vendorsList}
                paymentMethod={order.paymentMethod}
              />
          ))}
          </OrderDetailsContainer>
          <SubTotal order={order} />
          <Box
            sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <StyledButton type="submit" isLoading={submitLoading}>
              Submit
            </StyledButton>
            <GlobalUpdatesMessage {...isSuccessMsg} />
          </Box>
        </Stack>
      </FormProvider>
      <Modal
        title={type === 'SHIPPING' ? "SHIPPING ADDRESS" : "DROP SHIP"}
        open={!!open}
        handleClose={handleClose}
      >
        {
          open && (
            <ShipToDropShipModal
              open={open}
              type={type}
              shippingLink={shippingLink}
            />
          )
        }
      </Modal>
    </DropShipShipToContext.Provider>
  );
};

export default OrderDetails;
