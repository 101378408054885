import { InputTypes, ValidFieldNames } from '@utils/globalTypes';

export const inputsRows: ValidFieldNames[] = [
  {
    label: 'Printing Estimate',
    field: 'printingEstimate',
    type: InputTypes.text,
    inputprops: {
      width: '165px',
    },
  },
  {
    label: 'Mailing Services Estimate',
    field: 'mailingServiceEstimate',
    type: InputTypes.text,
    inputprops: {
      width: '165px',
    },
  },
  {
    label: 'Status',
    field: 'status',
    type: InputTypes.select,
    isRequired: true,
    selectprops: {
      width: '165px',
    },
  },
  {
    label: 'Notes',
    field: 'notes',
    type: InputTypes.textarea,
    textareaprops: {
      vtResize: true,
      height: 'auto',
    },
  },
];

export const estimateSizeQuoteStatusOptions = [
  {
    value: 'received',
    optionName: 'Received',
  },
  {
    value: 'complete',
    optionName: 'Complete',
  },
  {
    value: 'rejected',
    optionName: 'Rejected',
  },
];
