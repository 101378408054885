/* eslint-disable max-len */
import { SVGProps } from "react";

const PsdIcon = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_5020_20362)">
      <rect width="30" height="30" fill="#32CAFA" />
      <rect x="1" y="1" width="23" height="23" fill="#021D25" />
      <path
        d="M4.28516 5.25752C4.28516 5.19073 4.42931 5.13945 4.51271 5.13945C5.16795 5.10605 6.12938 5.08936 7.13846 5.08936C9.96079 5.08936 11.0604 6.63738 11.0604 8.62071C11.0604 11.2111 9.18402 12.3214 6.88232 12.3214C6.49632 12.3214 6.36169 12.3047 6.09245 12.3047V16.2213C6.09245 16.306 6.05909 16.3394 5.9745 16.3394H4.4031C4.31852 16.3394 4.28516 16.306 4.28516 16.2213V5.25752ZM6.09245 10.6732C6.32715 10.6899 6.513 10.6899 6.91568 10.6899C8.10823 10.6899 9.2293 10.2689 9.2293 8.65529C9.2293 7.36011 8.4287 6.70416 7.06698 6.70416C6.6643 6.70416 6.27711 6.72086 6.09245 6.73756V10.6732ZM14.8573 9.46151C14.0507 9.46151 13.7815 9.86581 13.7815 10.2021C13.7815 10.5718 13.9661 10.8247 15.0419 11.3792C16.6384 12.1533 17.1423 12.8927 17.1423 13.9863C17.1423 15.6178 15.8985 16.3394 14.2187 16.3394C13.3288 16.3394 12.5699 16.1592 12.1338 15.9064C12.0671 15.873 12.0492 15.8229 12.0492 15.7382V14.3894C12.0492 14.288 12.0993 14.2547 12.1672 14.3047C12.8058 14.7257 13.5468 14.9106 14.2187 14.9106C15.0253 14.9106 15.3612 14.5743 15.3612 14.1199C15.3612 13.7502 15.1265 13.4306 14.1008 12.8927C12.6556 12.2033 12.0504 11.4961 12.0504 10.319C12.0504 9.00712 13.075 8.01814 14.8561 8.01814C15.7293 8.01814 16.3465 8.04825 16.6824 8.19971C16.767 8.2498 16.7837 8.33448 16.7837 8.40127V9.79783C16.7837 9.88131 16.7337 9.93259 16.6324 9.8992C16.3084 9.7068 15.6638 9.46151 14.8573 9.46151Z"
        fill="#32CAFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_5020_20362">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PsdIcon;
