import {
  createOptionalTextWidthValidation,
  createRequiredTextWidthValidation,
} from "@utils/schemas";
import { InputTypes, ValidFieldNames } from "@utils/globalTypes";
import * as yup from "yup";
import { allFilesExtensions } from "@containers/common/FileUploader/helpers";

export interface IAddDataForm {
  id?: string;
  name: string;
  pdfFile?: string;
  aiFile?: string;
  psdFile?: string;
  inddFile?: string;
  subCategoryId: string;
  templateCategoryId?: string;
}

export const defaultValues = {
  name: "",
  pdfFile: "",
  aiFile: "",
  psdFile: "",
  inddFile: "",
  subCategoryId: "",
  templateCategoryId: "",
};

export const AddDataSchema = yup.object().shape({
  name: createRequiredTextWidthValidation("Name", 255),
  subCategoryId: createRequiredTextWidthValidation("Subcategory", 255),
});

export const inputsRows: ValidFieldNames[] = [
  {
    label: "Product Category",
    field: "subCategoryId",
    type: InputTypes.select,
    isRequired: true,
  },
  {
    label: "Template Name",
    field: "name",
    type: InputTypes.text,
    isRequired: true,
  },
  {
    label: "PDF File",
    field: "pdfFile",
    type: InputTypes.file,
    isRequired: false,
    extensions: [...allFilesExtensions],
  },
  {
    label: "AI File",
    field: "aiFile",
    type: InputTypes.file,
    isRequired: false,
    extensions: [...allFilesExtensions],
  },
  {
    label: "PSD File",
    field: "psdFile",
    type: InputTypes.file,
    isRequired: false,
    extensions: [...allFilesExtensions],
  },
  {
    label: "INDD File",
    field: "inddFile",
    type: InputTypes.file,
    isRequired: false,
    extensions: [...allFilesExtensions],
  },
];

export const formattedPayload = (data: IAddDataForm) => ({
  ...data,
  templateCategoryId: data.templateCategoryId || null,
});
